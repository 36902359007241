<template>
    <div>
        <b-loading v-if="loading" v-model="loading" :is-full-page="false" />
        <div v-else>
            <section id="userDetails" class="box">
                <PartnerInfos type="user" :user="user" :counts="counts" @reloadData="getData" />
            </section>

            <section id="partnerDetailsTabs" class="box">
                <b-tabs v-model="activeTab" @input="getTabData">
                    <b-tab-item
                        :label="`Attestations (${
                            certificates[certificates.length - 1] &&
                            certificates[certificates.length - 1].status == 'validate'
                                ? 'Valide'
                                : 'Non valide'
                        })`"
                    >
                        <CertificateTable :certificates="certificates" :user="user" @reloadData="reloadData" />
                    </b-tab-item>
                    <b-tab-item :label="`Sites (${countSites})`">
                        <SiteTable :user="user" :display-user="false" @countSites="updateCount" />
                    </b-tab-item>
                    <b-tab-item :label="`Gains (${earnToCurrency})`">
                        <PartnerDetailEarningTable :tab-data="earnsData" @reloadData="reloadData" />
                    </b-tab-item>
                    <b-tab-item
                        :label="`Crédits (${creditToCurrency})`"
                    >
                        <UserCreditTab :tab-data="creditsData" @reloadData="reloadData" />
                    </b-tab-item>
                    <b-tab-item :label="`Parrainage (${counts.sponsorship ? counts.sponsorship : 0})`">
                        <PartnerDetailSponsorship :tab-data="sponsorshipsData" @reloadData="reloadData" />
                    </b-tab-item>
                    <b-tab-item :label="`Commandes partenaire (${counts.contents})`">
                        <PartnerDetailsContents :tab-data="contentData" @reloadData="reloadData" />
                    </b-tab-item>
                    <b-tab-item :label="`Commandes client (${counts.orders})`">
                        <UserTrackOrderTab />
                    </b-tab-item>
                    <b-tab-item :label="`Projets (${counts.projects})`">
                        <UserProjectTab :tab-data="projectData" />
                    </b-tab-item>
                    <b-tab-item :label="`Accompagnement (${counts.campaigns})`">
                        <UserCampaignTab :tab-data="campaignData" />
                    </b-tab-item>
                    <b-tab-item label="Recherche">
                        <PartnerDetailsSearch :user="user" />
                    </b-tab-item>
                    <b-tab-item label="Notes">
                        <PartnerDetailsNote :tab-data="noteData" @reloadData="reloadData" />
                    </b-tab-item>
                </b-tabs>
            </section>
        </div>
    </div>
</template>

<script>
    import PartnerInfos from '../../../components/Admin/Partner/PartnerInfos'
    import SiteTable from '../../../components/Admin/Partner/SiteTable'
    import CertificateTable from '../../../components/Admin/Partner/CertificateTable'
    import PartnerDetailEarningTable from '../../../components/Admin/Partner/EarningsTable'
    import PartnerDetailSponsorship from '../../../components/Admin/Partner/Sponsorship'
    import PartnerDetailsContents from '../../../components/Admin/Partner/ContentsTab'
    import PartnerDetailsSearch from '../../../components/Admin/Partner/SearchTab'
    import PartnerDetailsNote from '../../../components/Admin/Partner/NoteTab'
    import UserTrackOrderTab from '../../../components/Admin/Partner/UserTrackOrderTab'
    import UserProjectTab from '../../../components/Admin/Partner/UserProjectTab'
    import UserCreditTab from '../../../components/Admin/Partner/UserCreditTab'
    import UserCampaignTab from '../../../components/Admin/Partner/UserCampaingTab'

    export default {
        name: 'UserDetails',
        components: {
            UserCampaignTab,
            UserCreditTab,
            UserProjectTab,
            UserTrackOrderTab,
            PartnerDetailsNote,
            PartnerDetailsSearch,
            PartnerDetailsContents,
            PartnerDetailSponsorship,
            PartnerDetailEarningTable,
            CertificateTable,
            PartnerInfos,
            SiteTable
        },
        data() {
            return {
                loading: true,
                activeTab: 0,
                tabData: {},
                user: {},
                counts: [],
                tabs: [],

                countSites: 0,

                certificates: [],
                certificatesData: {},
                earnsData: {},
                creditsData: {},
                sponsorshipsData: [],
                contentData: [],
                trackOrderData: [],
                projectData: [],
                campaignData: [],
                noteData: []
            }
        },
        created() {
            this.getData()
        },
        computed: {
            earnToCurrency() {
                return this.$options.filters.toCurrency(
                    this.user.gains ? parseInt(this.user.gains * 100) / 100 : 0
                )
            },
            creditToCurrency() {
                return this.$options.filters.toCurrency(
                    this.user.credit_amount ? parseInt(this.user.credit_amount * 100) / 100 : 0
                )
            }
        },
        methods: {
            getData() {
                this.axios
                    .get('/admin/partner-details/' + this.$route.query.id)
                    .then(response => {
                        this.loading = false
                        this.user = response.data.user
                        this.certificates = response.data.certificates
                        this.counts = response.data.counts

                        this.counts.expiration =
                            this.certificates.length == 0
                                ? null
                                : this.certificates[this.certificates.length - 1].expiration
                    })
                    .catch(e => {
                        console.log(e)
                        this.$store.dispatch('toastError', e)
                    })
            },
            updateCount(data) {
                this.countSites = data
            },
            getTabData(index = this.activeTab) {
                switch (index) {
                    case 1:
                        this.certificatesData = {
                            index: this.activeTab,
                            user: this.user
                        }

                        this.getData()

                        break

                    case 2:
                        this.axios
                            .get(`/admin/users/${this.user.id}/earning`)
                            .then(response => {
                                this.earnsData = {
                                    index: this.activeTab,
                                    user: this.user,
                                    content: response.data.earnings
                                }
                            })
                            .catch(e => {
                                console.log(e)
                                this.$store.dispatch('toastError', e)
                            })

                        break

                    case 3:
                        this.axios
                            .get(`/admin/users/${this.user.id}/credit`)
                            .then(response => {
                                this.creditsData = {
                                    index: this.activeTab,
                                    user: this.user,
                                    content: response.data.credits
                                }
                            })
                            .catch(e => {
                                console.log(e)
                                this.$store.dispatch('toastError', e)
                            })

                        break

                    case 4:
                        this.axios
                            .get(`/admin/users/${this.user.id}/filleuls`)
                            .then(response => {
                                this.sponsorshipsData = {
                                    index: this.activeTab,
                                    user: this.user,
                                    content: response.data.filleuls
                                }
                            })
                            .catch(e => {
                                console.log(e)
                                this.$store.dispatch('toastError', e)
                            })

                        break

                    case 5:
                        this.axios
                            .get(`/admin/partner-details/contents/${this.$route.query.id}`)
                            .then(response => {
                                this.contentData = {
                                    index: this.activeTab,
                                    user: this.user,
                                    content: response.data
                                }
                            })
                            .catch(e => {
                                console.log(e)
                                this.$store.dispatch('toastError', e)
                            })

                        break

                    /*case 6:
            this.axios
                .get(`/admin/user-details/track-orders/${this.$route.query.id}`)
                .then((response) => {
                  this.trackOrderData = {
                    index: this.activeTab,
                    user: this.user,
                    content: response.data
                  };
                })
                .catch((e) => {
                  console.log(e);
                  this.$store.dispatch("toastError", e);
                });

            break;*/

                    case 7:
                        this.axios
                            .get(`/admin/user-details/projects/${this.$route.query.id}`)
                            .then(response => {
                                this.projectData = {
                                    index: this.activeTab,
                                    user: this.user,
                                    content: response.data
                                }
                            })
                            .catch(e => {
                                console.log(e)
                                this.$store.dispatch('toastError', e)
                            })

                        break

                    case 8:
                        this.axios
                            .get(`/admin/user-details/campaigns/${this.$route.query.id}`)
                            .then(response => {
                                this.campaignData = {
                                    index: this.activeTab,
                                    user: this.user,
                                    content: response.data
                                }
                            })
                            .catch(e => {
                                console.log(e)
                                this.$store.dispatch('toastError', e)
                            })

                        break

                    case 10:
                        this.axios
                            .get(`/admin/note/${this.$route.query.id}`)
                            .then(response => {
                                this.noteData = {
                                    index: this.activeTab,
                                    user: this.user,
                                    content: response.data
                                }
                            })
                            .catch(e => {
                                console.log(e)
                                this.$store.dispatch('toastError', e)
                            })

                        break

                    default:
                        break
                }
            },
            reloadData(tab) {
                tab == null ? this.getData() : this.getTabData(tab)
            }
        }
    }
</script>
